import React, { useState, useEffect, useRef } from 'react';
import Typed from 'typed.js';
import './App.css';
import logo from './logo.jpeg'; // Adjust the path according to where the image is saved.

const themes = {
  portfolio: {
    themeBGColor: "#FFFCF3",
    themeToolbarColor: "#F6E0C8",
    themeColor: "#333333",
    themePromptColor: "#EB5E28",
    terminalBGColor: "#FFFFFF",
    errorColor: "#FF443E",
    successColor: "#5B9E47", // Green color for success messages
    commandColor: "#EB5E28", // Orange color for spin email_module, initializing..., and please enter your email
  }
};

const steps = [
  'welcome to spin',
  'command the cloud: simplify, automate, and monitor with a single chat ☁️',
  'sign up to get early access and be the first to know when we launch.',
  '$ spin up',
  '$ spin email_module',
  'initializing...',
  'please enter your email'
];

function App() {
  const [messages, setMessages] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const inputRef = useRef(null);
  const typedRef = useRef(null);
  const theme = themes.portfolio;

  useEffect(() => {
    if (currentStep < steps.length) {
      const timeout = setTimeout(() => {
        typedRef.current = new Typed(`#typed-step-${currentStep}`, {
          strings: [steps[currentStep]],
          typeSpeed: 25,
          showCursor: false,
          onComplete: () => {
            setMessages((prevMessages) => [...prevMessages, steps[currentStep]]);
            setCurrentStep((prev) => prev + 1);
          }
        });
      }, 100); // Adding a 1-second delay between each sentence

      return () => {
        clearTimeout(timeout);
        typedRef.current?.destroy();
      };
    } else if (currentStep === steps.length) {
      setTimeout(() => inputRef.current.focus(), 100);
    }
  }, [currentStep]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = inputRef.current.value;

    // Step 1: Authenticate and get the access token
    const authResponse = await fetch('https://api.sendpulse.com/oauth/access_token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'client_credentials',
        client_id: 'b929e9fa8e10ec8b856ec3be7001d44e',  // Your API ID
        client_secret: 'f78550524c98538449638f8d08f15a62',  // Your Secret
      }),
    });

    const authData = await authResponse.json();

    if (authData.access_token) {
      try {
        // Step 2: Use the access token to store the email in the mailing list
        const response = await fetch('https://api.sendpulse.com/addressbooks/863581/emails', { // Use your actual Address Book ID
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authData.access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            emails: [email],
          }),
        });

        if (response.ok) {
          setMessages((prevMessages) => [
            ...prevMessages,
            `$ email ${email}`,
            '$email_module signup successful'
          ]);
          setEmailSubmitted(true);
          setTimeout(() => {
            setCurrentStep(currentStep + 1); // Trigger the final message typing
          }, 500);
        } else {
          console.error('Failed to submit email');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      console.error('Failed to authenticate with SendPulse API');
    }
  };

  useEffect(() => {
    if (currentStep === steps.length + 1) {
      typedRef.current = new Typed(`#typed-final`, {
        strings: ['thanks for signing up :)'],
        typeSpeed: 1000, // 30% faster than the previous speed
        showCursor: false,
      });
    }
  }, [currentStep]);

  const getStyledMessage = (message) => {
    if (typeof message === 'string') {
      if (message === '$ spin email_module') {
        return <span style={{ fontWeight: 'bold', color: theme.commandColor }}>{message}</span>;
      }
      if (message === '$ spin up') {
        return <span style={{ fontWeight: 'bold', color: theme.commandColor }}>{message}</span>;
      }
      if (message === 'initializing...') {
        return <span style={{ color: theme.commandColor, paddingLeft: '20px' }}>{message}</span>;
      }
      if (message === 'please enter your email') {
        return <span style={{ color: theme.commandColor }}>{message}</span>;
      }
      if (message.includes('all around Cloud')) {
        return <span style={{ fontWeight: 'bold' }}>{message}</span>;
      }
      if (message.includes('$email_module signup successful')) {
        return <span style={{ fontWeight: 'bold', color: theme.successColor }}>{message}</span>;
      }
      if (message.includes('thanks for signing up :)')) {
        return <span style={{ fontWeight: 'bold', color: theme.successColor }}>{message}</span>;
      }
      if (message.startsWith('$ email')) {
        return <span style={{ fontWeight: 'bold', color: theme.commandColor }}>{message}</span>;
      }
      return message;
    }
    return null;
  };

  return (
    <div
      className="App"
      style={{
        backgroundColor: theme.themeBGColor,
        backgroundImage: `url(${logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top left',
        backgroundSize: '100px 100px' // Adjust size as needed
      }}
    >
      <div className="terminal" style={{ backgroundColor: theme.terminalBGColor }}>
        <div className="terminal-toolbar" style={{ backgroundColor: theme.themeToolbarColor }}>
          <div className="terminal-buttons">
            <div className="terminal-btn close"></div>
            <div className="terminal-btn minimize"></div>
            <div className="terminal-btn expand"></div>
          </div>
          <div className="terminal-title">
            tryspin.io - zsh
          </div>
        </div>
        <div className="terminal-content" style={{ backgroundColor: theme.terminalBGColor, color: theme.themeColor }}>
          {messages.map((message, index) => (
            <div key={index} style={{ color: theme.themeColor }}>
              {getStyledMessage(message)}
            </div>
          ))}
          {currentStep < steps.length && <span id={`typed-step-${currentStep}`} />}
          {(currentStep >= steps.length && !emailSubmitted) && (
            <form onSubmit={handleSubmit} className="terminal-input-line">
              <input
                type="email"
                ref={inputRef}
                placeholder="you@example.com"
                style={{
                  color: theme.themeColor,
                  backgroundColor: '#f9f9f9',
                  border: '2px solid #ccc',
                  borderRadius: '4px',
                  padding: '10px',
                  width: '300px',
                  fontSize: '16px',
                  marginTop: '10px'
                }}
                onChange={() => {}}
              />
              <button type="submit" style={{ display: 'none' }}></button>
            </form>
          )}
          {currentStep === steps.length + 1 && (
            <div id="typed-final" style={{ color: theme.successColor }}></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
